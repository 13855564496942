<template>
  <div class="app-footer">
    <div class="content">
      <div class="row middle-xs middle-sm middle-md middle-lg">
        <div class="footer-area-1 col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <p>
            <img src="@/assets/icons/follow.png" class="icon" alt="follow">
            <span>关注我们</span>
          </p>
          <p>
            <img src="@/assets/playco_QRcode.png" alt="玩刻公众号二维码" class="qr-code">
          </p>
        </div>
        <div class="footer-area-2 col-xs- col-sm-6 col-md-6 col-lg-6">
          <ul class="about-list">
            <li class="about-item">
              <img src="@/assets/icons/phone.png" class="icon" alt="phone">
              <span class="about-label">联系电话</span>
              <span>400-603 2650</span>
            </li>
            <li>
              <img src="@/assets/icons/address.png" class="icon" alt="address">
              <span class="about-label">公司地址</span>
              <span>广东省深圳市福田区天安数码城天吉大厦CD座6C201</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="footer-area-3 col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <a href="https://beian.miit.gov.cn" class="gov" target="_blank">粤ICP备17093038号</a>
        </div>
        <div class="footer-area-4 copyright col-xs- col-sm-6 col-md-6 col-lg-6">
          Copyright © 2023 玩刻游戏
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-footer {
  min-height: var(--footer-min-height);
  background-color: #0a0a0a;
  color: #fff;
}

.content {
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 40px 24px;
  height: 100%;
  overflow-x: hidden;
}

.icon {
  vertical-align: middle;
  margin-right: 10px;
}

.qr-code {
  width: 136px;
  height: 136px;
}

.footer-area-1 {
  text-align: center;
}

.about-list {
  list-style: none;
  padding: 0;
}

.about-item {
  list-style: none;
}

.about-label {
  margin-right: 20px;
}

.footer-area-3 {
  text-align: center;
}

.gov {
  color: #fff;
}

.gov:hover {
  color: var(--primary-color);
}

.copyright {
  font-size: 0.75rem;
}

/* xs, mobile */
@media only screen and (max-width: 48em) {
  .footer-area-1 {
    text-align: left;
  }

  .footer-area-3 {
    text-align: left;
  }
}
</style>
