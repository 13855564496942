<!-- 加盟意向表 -->
<template>
  <div class="joining-intention">
    <h3 class="ji__title">申请合作</h3>
    <p class="ji__desc"><a href="https://www.workec.com/form/dynamic/oHiXFmbm95k%3D.html" class="ji__desc-link" target="_blank">智能时代 • 商机一触即发</a></p>
    <strong>
      <a href="https://www.workec.com/form/dynamic/oHiXFmbm95k%3D.html" class="ji__link" target="_blank">加盟意向表</a>
    </strong>
  </div>
</template>

<style scoped>
@font-face {
  font-family: 'Zhi Mang Xing';
  src: url('../assets/font-spider/ZhiMangXing-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.joining-intention {
  text-align: center;
}

.ji__title {
  font-size: 2em;
  margin-top: 0;
}

.ji__desc {
  font-family: 'Zhi Mang Xing', cursive;
  font-size: min(4vw, 3rem);
}

.ji__desc-link {
  color: #000;
  text-decoration: none;
}

.ji__link {
  font-size: 1.8rem;
  font-weight: normal;
  color: var(--primary-color, #1abce8);
}

/* xs, mobile */
@media only screen and (max-width: 48em) {
  .ji__title {
    font-size: 1.5em;
  }

  .ji__desc {
    font-size: 6vw;
  }
}
</style>
