<template>
  <div class="fixed-box">
    <header class="app-header row">
      <div class="logo col-xs-6 col-sm-2 col-md-2 col-lg-2">
        <router-link to="/">
          <img
            class="logo__img"
            alt="玩刻logo"
            :src="require('@/assets/logo.png')"
          />
        </router-link>
      </div>
      <nav class="app-nav col-xs-10 col-sm-10 col-md-10 col-lg-10">
        <ul class="nav-list row end-xs end-sm end-md end-lg">
          <li v-for="link in links" :key="link.path" class="nav-item">
            <router-link
              :to="link.path"
              class="nav-item__link"
              :class="{'nav-item__link--active': link.isActive}"
            >{{link.text}}</router-link>
          </li>
        </ul>
      </nav>
      <div class="mobile-nav-warp row end-xs col-xs-6">
        <button @click="toggleMobileNav" class="toggle-btn">
          <img
            :src="require(showMobileNav ? '@/assets/icons/cancel.svg' : '@/assets/icons/menu.svg')"
            height="30"
            alt="Menu"
          />
        </button>
        <nav class="mobile-nav" :class="{'mobile-nav--show': showMobileNav}">
          <ul @click="closeMobileNav" class="mobile-nav-list">
            <li v-for="link in links" :key="'m-' + link.path" class="mobile-nav-item">
              <router-link
                :to="link.path"
                class="mobile-nav-item__link"
                :class="{'mobile-nav-item__link--active': link.isActive}"
              >{{link.text}}</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { navLinks } from '../router';

export default {
  name: 'AppHeader',
  data() {
    return {
      showMobileNav: false,
    };
  },
  computed: {
    path() {
      return this.$route.path;
    },
    // 导航链接
    // 欲添加导航链接，请在 router/index.js 中添加路由，
    // 在路由的 meta 里设置 isNav: true 以及 navText 即可。
    links() {
      if (Array.isArray(navLinks)) {
        return navLinks.map((v) => ({
          path: v.path,
          text: v.meta.navText,
          isActive: this.path === v.path,
        }));
      }
      return [];
    },
  },
  methods: {
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    },
    closeMobileNav() {
      this.showMobileNav = false;
    },
  },
};
</script>

<style scoped>
.fixed-box {
  position: fixed;
  width: 100%;
  height: var(--header-height);
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgba(194,210,238,.33);
  z-index: 10000;
}

.app-header {
  height: var(--header-height);
  max-width: var(--content-width);
  margin: 0 auto;
  font-size: 1rem;
}

.logo {
  height: var(--header-height);
  padding: calc((var(--header-height) - 30px) / 2) 0;
}

.logo__img {
  height: 30px;
  margin-left: 24px;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  list-style: none;
  height: var(--header-height);
  line-height: var(--header-height);
  margin: 0 32px;
}

.nav-item__link {
  display: inline-block;
  color: var(--deepgray);
  text-decoration: none;
}

.nav-item__link:hover {
  color: var(--primary-color);
}

.nav-item__link--active {
  color: var(--primary-color);
  font-weight: bold;
}

/* xs, mobile */
@media only screen and (max-width: 48em) {
  .app-nav {
    display: none;
  }
}

.mobile-nav-warp {
  display: none;
}

/* xs, mobile */
@media only screen and (max-width: 48em) {
  .mobile-nav-warp {
    display: block;
    position: relative;
  }
}

.toggle-btn {
  outline: none;
  border: none;
  height: 100%;
  width: 40px;
  cursor: pointer;
  background-color: #fff;
}

.mobile-nav {
  position: fixed;
  width: 100vw;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear, top 0.2s linear;
  z-index: 1000;
}

.mobile-nav--show {
  top: 72px;
  visibility: visible;
  opacity: 1;
}

.mobile-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav-item {
  text-align: center;
  list-style: none;
}

.mobile-nav-item__link {
  color: var(--deepgray);
  text-decoration: none;
  display: inline-block;
  padding: 0.8rem 0;
  width: 100%;
}

.mobile-nav-item__link:hover {
  color: var(--primary-color);
}

.mobile-nav-item__link--active {
  color: var(--primary-color);
  font-weight: bold;
}
</style>
