<template>
  <div class="app-banner">
    <img :src="src" alt="banner image" class="app-banner__img">
    <div class="column">
      <div class="block">
        <h1 class="block__title">{{title || ''}}</h1>
        <p
          class="block__text"
          v-if="Array.isArray(content) && content.length"
        >
          <span
            class="block__item"
            v-for="(item, index) in content"
            :key="index"
          >{{item}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBanner',
  props: {
    src: {
      type: String,
    },
    title: String,
    content: [Array, Set],
  },
};
</script>

<style scoped>
.app-banner {
  position: relative;
  overflow: hidden;
  height: 740px;
}

.app-banner__img {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 280px;
  z-index: -1;
}

.column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  width: 50%;
  margin: auto;
  padding: 2em;
  backdrop-filter: blur(2px);
}

.block__title {
  font-size: 3.125vw;
  font-weight: normal;
  text-align: center;
  margin-top: 0;
}

.block__text {
  text-align: center;
}

.block__item {
  font-size: 1rem;
}

.block__item::after {
  content: "|";
  margin: 6px;
  vertical-align: 1px;
}

.block__item:last-child::after {
  content: "";
}

/* xs, mobile */
@media only screen and (max-width: 48em) {
  .app-banner {
    height: calc((100vw * 9) / 16);
  }

  .block {
    width: 75%;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .block__title {
    font-size: 1.2rem;
  }

  .block__item {
    font-size: 0.75rem;
  }
}
</style>
