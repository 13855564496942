import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const DEFAULT_TITLE = '玩刻Playco主机游戏馆-全商用版权正版运营';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '玩刻Playco主机游戏馆-全商用版权正版运营',
      isNav: true,
      navText: '产品服务',
    },
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('../views/Partner.vue'),
    meta: {
      title: '招商加盟 - 玩刻 playco',
      isNav: true,
      navText: '招商加盟',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: '关于我们 - 玩刻 playco',
      isNav: true,
      navText: '关于我们',
    },
  },
];

// 从路由中自动生成应用导航
// 欲添加应用导航链接，请在 routes 中的 meta 里添加 isNav: true 以及 navText
const navLinks = routes.filter((v) => v.meta.isNav && v.meta.navText);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export {
  navLinks,
  router,
};
