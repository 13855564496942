<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {

};
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  /* DO NOT add more css at here. */
}

html,
body {
  padding: 0;
  margin: 0;
  /* DO NOT add more css at here. */
}

/* global CSS vars */
:root {
  --deepgray: #333333;
  --primary-color: #1abce8;
  --content-width: 1200px;
  --header-height: 72px;
  --footer-min-height: 320px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--deepgray);
  font-size: 0.875rem;
}
</style>
