<template>
  <AppHeader></AppHeader>
  <div class="app-body">
    <app-banner
      :src="bannerImage"
      :content="['屏幕增值创收新利器', '立式控制平板界面简易操作', '热门游戏内容全覆盖', '成熟完善运营模式']"
      title="首款商业用途游戏主机"
    ></app-banner>

    <div>
      <section class="block-1 row center-xs center-sm">
        <div class="col-md-7 col-lg-7">
          <h2 class="home__title">绝佳性能的商用游戏主机</h2>
          <p class="block-1__desc">玩刻提供游戏主机、操作平板、游戏手柄。场地仅需提供网络，充分利用现有屏幕</p>
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <img src="@/assets/home_002.png" alt="性能">
              <p class="block-1__text">性能媲美，甚至超越市面上所有游戏主机：PlayStation4 Pro,Xbox,任天堂Switch</p>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <img src="@/assets/home_003.png" alt="画质">
              <p class="block-1__text">支持4K画质，拥有60帧以上的卓越画质体验（最高可支持120帧）</p>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <img src="@/assets/home_004.png" alt="硬盘">
              <p class="block-1__text">超大硬盘内置游戏，无需更换游戏碟片，无需任何维护</p>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <img src="@/assets/home_005.png" alt="触屏/手柄">
              <p class="block-1__text">触屏平板选择游戏，配合无线手柄享受畅快游戏体验</p>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-lg-5">
          <img src="@/assets/home_001.jpg" class="block-1__pic" alt="玩刻主机">
        </div>
      </section>

      <section class="block-2">
        <div class="block-2__content">
          <h2 class="home__title block-2__title">立式控制平板界面简易操作</h2>
          <div class="block-2__images">
            <img loading="lazy" src="@/assets/home_006.jpg" class="block-2__img" alt="操作说明">
          </div>
        </div>
      </section>

      <section class="block-3">
        <div class="block-3__content">
          <h2 class="home__title block-3__title">屏幕增值创收的新利器</h2>
          <div class="row center-xs start-sm start-md start-lg">
            <div class="block-3__item
                        col-xs-6 col-sm-4 col-md-4 col-lg-4
                        col-xs-offset-0 col-sm-offset-2 col-md-offset-2 col-lg-offset-2">
              <img src="@/assets/home_008.png" alt="增值">
              <h3 class="block-3__h3">娱乐增值，打造新卖点</h3>
              <p class="block-3__desc">为客户提供绝佳的游戏服务，为合作 方提供额外的增值收入</p>
            </div>
            <div class="block-3__item col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <img src="@/assets/home_009.png" alt="共赢">
              <h3 class="block-3__h3">合作方零投入共赢</h3>
              <p class="block-3__desc">合作方无额外投入，现有电视即插即 用，即时的营收数据，后台消费明细 透明，系统自动分账</p>
            </div>
            <div class="block-3__item col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <img src="@/assets/home_010.png" alt="移动支付">
              <h3 class="block-3__h3">便捷的移动付费通道</h3>
              <p class="block-3__desc">微信支付宝扫码付费通道，按时计费</p>
            </div>
            <div class="block-3__item col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <img src="@/assets/home_011.png" alt="云存档">
              <h3 class="block-3__h3">云存档数据提高回购率</h3>
              <p class="block-3__desc">支持云存档，用户微信登录即可云端提取过往游戏存档数据，游戏进程不间断，吸引顾客再次使用</p>
            </div>
            <div class="block-3__item col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <img src="@/assets/home_012.png" alt="售后">
              <h3 class="block-3__h3">完善的售后和服务体系</h3>
              <p class="block-3__desc">24小时客服支持，全国运维体系支撑，合作方无需额外维护成本，无需另买游戏或更换碟片</p>
            </div>
          </div>
        </div>
      </section>
  <!--
      <section class="block-4">
        <h2 class="home__title">热门游戏内容全覆盖</h2>
        <p class="block-4__desc">各大游戏厂家、费用版权全覆盖，与美国游戏巨头Valve达成商业用途授权合作，全商业版权正版运营</p>
        <div class="gallery">
          <div class="gallery__item" data-game-name="实况足球2021">
            <img loading="lazy" src="@/assets/gallery_001.jpg" class="gallery__img" alt="实况足球2021">
          </div>
          <div class="gallery__item" data-game-name="NBA 2K21">
            <img loading="lazy" src="@/assets/gallery_002.jpg" class="gallery__img" alt="NBA 2K21">
          </div>
          <div class="gallery__item" data-game-name="双人成行">
            <img loading="lazy" src="@/assets/gallery_003.png" class="gallery__img" alt="双人成行">
          </div>
          <div class="gallery__item" data-game-name="胡闹厨房2">
            <img loading="lazy" src="@/assets/gallery_004.png" class="gallery__img" alt="胡闹厨房2">
          </div>
          <div class="gallery__item" data-game-name="拳皇14">
            <img loading="lazy" src="@/assets/gallery_005.png" class="gallery__img" alt="拳皇14">
          </div>
          <div class="gallery__item" data-game-name="丸霸无双">
            <img loading="lazy" src="@/assets/gallery_006.jpg" class="gallery__img" alt="丸霸无双">
          </div>
          <div class="gallery__item" data-game-name="街霸5">
            <img loading="lazy" src="@/assets/gallery_007.png" class="gallery__img" alt="街霸5">
          </div>
          <div class="gallery__item" data-game-name="鹅作剧">
            <img loading="lazy" src="@/assets/gallery_008.jpg" class="gallery__img" alt="鹅作剧">
          </div>
          <div class="gallery__item" data-game-name="火影忍者4博人之路">
            <img loading="lazy" src="@/assets/gallery_009.png" class="gallery__img" alt="火影忍者4博人之路">
          </div>
          <div class="gallery__item" data-game-name="毛线小精灵2">
            <img loading="lazy" src="@/assets/gallery_010.jpg" class="gallery__img" alt="毛线小精灵2">
          </div>
          <div class="gallery__item" data-game-name="只狼：影逝二度">
            <img loading="lazy" src="@/assets/gallery_011.png" class="gallery__img" alt="只狼：影逝二度">
          </div>
          <div class="gallery__item" data-game-name="龙珠Z">
            <img loading="lazy" src="@/assets/gallery_012.png" class="gallery__img" alt="龙珠Z">
          </div>
          <div class="gallery__item" data-game-name="鬼泣5">
            <img loading="lazy" src="@/assets/gallery_013.png" class="gallery__img" alt="鬼泣5">
          </div>
          <div class="gallery__item" data-game-name="纽扣兄弟">
            <img loading="lazy" src="@/assets/gallery_014.png" class="gallery__img" alt="纽扣兄弟">
          </div>
          <div class="gallery__item" data-game-name="人类一败涂地">
            <img loading="lazy" src="@/assets/gallery_015.png" class="gallery__img" alt="人类一败涂地">
          </div>
        </div>
      </section>
   -->
      <section class="block-5">
        <h2 class="home__title block-5__title">玩家青睐无所不在</h2>
        <div class="row between-xs between-sm between-md between-lg">
          <div class="b5-left">
            <h3 class="block-5__h3">用户群体</h3>
            <p class="block-5__desc">涵盖各类用户群体</p>
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6
                          col-xs-offset-3 col-sm-offset-3 col-md-offset-3 col-lg-offset-3">
                <img loading="lazy" src="@/assets/home_013.png" class="b5-left__img" alt="亲子家庭">
                <p class="b5-left__desc">亲子家庭</p>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <img loading="lazy" src="@/assets/home_014.png" class="b5-left__img" alt="朋友/情侣">
                <p class="b5-left__desc">朋友/情侣</p>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <img loading="lazy" src="@/assets/home_015.png" class="b5-left__img" alt="爱玩">
                <p class="b5-left__desc">爱玩游戏的80、90、00后</p>
              </div>
            </div>
          </div>
          <div class="b5-right">
            <h3 class="block-5__h3">套餐服务</h3>
            <p class="block-5__desc">根据场地定位，制定最理想的游戏套餐价格，与美团点评APP战略合作推广</p>
            <div class="b5-right__img-box">
              <img loading="lazy" src="@/assets/home_016.png" class="b5-right__img" alt="免费试玩">
              <img loading="lazy" src="@/assets/home_017.png" class="b5-right__img" alt="试玩半小时">
              <img loading="lazy" src="@/assets/home_018.png" class="b5-right__img" alt="试玩一小时">
              <img loading="lazy" src="@/assets/home_019.png" class="b5-right__img" alt="全天封顶">
            </div>
          </div>
        </div>
      </section>

      <section class="block-6">
        <h2 class="home__title block-6__title">成熟完善运营模式</h2>
        <div class="block-6__content">
          <div>
            <img loading="lazy" src="@/assets/logo_white.png" class="block-6__logo" alt="logo">
          </div>
          <div class="b6-svgicon__box">
            <img loading="lazy" src="@/assets/icons/arrow_down.svg" class="b6-svgicon" alt="next">
          </div>
          <div class="b6-step2">
            <div>
              <img loading="lazy" src="@/assets/home_020.png" class="b6-step2__img" alt="商业版权授权">
              <p class="b6-step2__text">商业版权授权<br>版权分账管理</p>
            </div>
            <div>
              <img loading="lazy" src="@/assets/home_021.png" class="b6-step2__img" alt="互联网管理">
              <p class="b6-step2__text">互联网平台管理<br>设备状态监控</p>
            </div>
            <div>
              <img loading="lazy" src="@/assets/home_022.png" class="b6-step2__img" alt="收益透明">
              <p class="b6-step2__text">收益透明化<br>商家分账管理</p>
            </div>
            <div>
              <img loading="lazy" src="@/assets/home_023.png" class="b6-step2__img" alt="24小时运维">
              <p class="b6-step2__text">全国7天24小时<br>客服和运维体系</p>
            </div>
          </div>
          <div class="b6-svgicon__box">
            <img loading="lazy" src="@/assets/icons/arrow_down.svg" class="b6-svgicon" alt="next">
          </div>
          <div class="b6-step3">
            <h3 class="b6-step3__title">商用场景</h3>
            <div class="b6-gallery">
              <div>
                <img loading="lazy" src="@/assets/home_024.png" class="b6-gallery__img" alt="商场">
                <p>商场</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_025.png" class="b6-gallery__img" alt="电影院">
                <p>电影院</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_026.png" class="b6-gallery__img" alt="游戏厅">
                <p>游戏厅</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_027.png" class="b6-gallery__img" alt="餐饮店">
                <p>餐饮店</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_028.png" class="b6-gallery__img" alt="飞机场">
                <p>飞机场/高铁站</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_029.png" class="b6-gallery__img" alt="水疗浴场">
                <p>水疗浴场</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_030.png" class="b6-gallery__img" alt="酒店/民宿">
                <p>酒店/民宿</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_031.png" class="b6-gallery__img" alt="长租公寓">
                <p>长租公寓</p>
              </div>
              <div>
                <img loading="lazy" src="@/assets/home_032.png" class="b6-gallery__img" alt="私人影院">
                <p>私人影院</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="block-7">
        <joining-intention></joining-intention>
      </section>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import bannerImage from '@/assets/banner_home.jpg';

import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppBanner from '@/components/AppBanner.vue';
import JoiningIntention from '@/components/JoiningIntention.vue';

export default {
  name: 'Home',
  components: {
    AppHeader,
    AppFooter,
    AppBanner,
    JoiningIntention,
  },
  data() {
    return {
      bannerImage,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.app-body {
  /* 确保能将 footer 挤到底部 */
  min-height: calc(100vh - var(--header-height) - var(--footer-min-height));
  margin-top: var(--header-height);
}

.home__title {
  font-size: 2em;
  text-align: center;
}

.block-1 {
  max-width: var(--content-width);
  margin: 10vh auto 0;
  text-align: center;
}

.block-1__desc {
  margin: 45px 0;
  font-size: 1rem;
}

.block-1__text {
  /* 理想值 15ic, 每行15个汉字宽度（ic 表示"水"的字形宽度，但浏览器尚未实现） */
  /* 27ch 是对 15ic 的近似（ch 表示 "0" 的字形宽度）。 */
  max-width: 27ch;
  margin: 20px auto 30px;
}

.block-1__pic {
  width: 100%;
}

.block-2 {
  max-width: var(--content-width);
  margin: 10vh auto 8vh;
}

.block-2__title {
  margin: 0;
}

.block-2__content {
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
  padding: 10vh 24px 24px;
}

.block-2__images {
  display: flex;
  justify-content: center;
}

.block-2__img {
  width: 100%;
}

.block-3 {
  background-image: linear-gradient(90deg, #5E676A 0%, #414749 100%);
  color: #fff;
  margin-bottom: 8vh;
}

.block-3__content {
  max-width: var(--content-width);
  margin: auto;
  padding: 10vh 24px;
}

.block-3__title {
  margin: 0 0 2em;
}

.block-3__item {
  text-align: center;
}

.block-3__h3 {
  font-weight: 500;
  font-size: 1.2em;
}

.block-3__desc {
  max-width: 35ch;
  margin-left: auto;
  margin-right: auto;
}

.block-4 {
  text-align: center;
  margin: 0 auto 8vh;
  max-width: calc(min(264px, 18vw) * 5 + 5px * 5);
}

.block-4__desc {
  font-size: 1rem;
  margin: 2em;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.gallery__item {
  position: relative;
  width: min(264px, 18vw);
  height: calc(min(264px, 18vw) * 192 / 264);
  margin: 2.5px;
  overflow: hidden;
}

.gallery__item:hover {
  overflow: visible;
}

.gallery__item:hover::before {
  content: attr(data-game-name);
  position: absolute;
  display: inline-block;
  background-color: var(--deepgray);
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 55% 80%, 50% 100%, 45% 80%, 0% 80%);
  color: #fff;
  height: 4ch;
  line-height: 3.5ch;
  top: -6ch;
  width: 16ch;
  left: calc((100% - 16ch) / 2);
  font-size: smaller;
  z-index: 1000;
}

.gallery__img {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
  height: 100%;
  transition: height .2s;
}

.gallery__img:hover {
  height: 115%;
  z-index: 100;
}

.block-5 {
  max-width: min(calc(var(--content-width) + 150px), 96vw);
  margin: 0 auto 10vh;
}

.block-5__title {
  margin-bottom: 2em;
}

.block-5__h3 {
  font-size: 1.5em;
  font-weight: normal;
  text-align: center;
}

.block-5__desc {
  font-size: 1rem;
  max-width: 37ch;
  margin: 0 auto 3em;
  text-align: center;
}

.b5-left {
  box-shadow: -1px 1px 3px 0px rgb(0 0 0 / 25%);
  width: calc(50% - 15px);
  margin-right: 15px;
  padding: 5vw;
  text-align: center;
}

.b5-left__img {
  max-width: min(100%, 180px);
}

.b5-left__desc {
  text-align: center;
}

.b5-right {
  box-shadow: 1px 1px 3px 0px rgb(0 0 0 / 25%);
  width: calc(50% - 15px);
  margin-left: 15px;
  padding: 5vw;
}

.b5-right__img-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}

.b5-right__img {
  width: 100%;
}

.block-6 {
  background-image: linear-gradient(90deg, #5E676A 0%, #414749 100%);
  color: #fff;
  padding: 8vh 16px;
  text-align: center;
}

.block-6__title {
  margin-bottom: 2em;
}

.block-6__logo {
  width: 18%;
}

.b6-svgicon__box {
  padding: 2em 0;
}

.b6-svgicon {
  width: 2em;
}

.block-6__content {
  max-width: calc(var(--content-width) + 150px);
  margin: auto;
}

.b6-step2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  background-color: #717779;
  border-radius: 20px;
  padding: 3vw;
}

.b6-step2__img {
  max-width: 100%;
}

.b6-step3 {
  background-color: #717779;
  border-radius: 20px;
  padding: 3vw;
}

.b6-step3__title {
  margin-bottom: 2em;
}

.b6-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
}

.b6-gallery__img {
  max-width: 100%;
}

.block-7 {
  margin: 8vh;
}

/* xs, mobile */
@media only screen and (max-width: 48em) {
  .app-body {
    margin-top: var(--header-height);
  }

  .home__title {
    font-size: 1.5em;
  }

  .block-1__text {
    max-width: 20ch;
  }

  .gallery__item:hover::before {
    top: -5ch;
  }

  .b5-left {
    padding: 12px;
  }

  .b5-right {
    padding: 12px;
  }

  .block-6__logo {
    width: 30%;
  }

  .b6-step2__text {
    font-size: 0.75em;
  }
}
</style>
